import { render, staticRenderFns } from "./PageBargain.vue?vue&type=template&id=1c2a5b5e&scoped=true&"
import script from "./PageBargain.vue?vue&type=script&lang=js&"
export * from "./PageBargain.vue?vue&type=script&lang=js&"
import style0 from "./PageBargain.vue?vue&type=style&index=0&id=1c2a5b5e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c2a5b5e",
  null
  
)

export default component.exports